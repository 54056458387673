<template>
    <v-container class="mb-10">
        <v-row class="mx-0">
            <v-col class="col-service" cols="12" md="6">
                <div style="width: 100%">
                <label class="d-block font-weight-bold yellow--text text--darken-3">MOVE EXPRESS</label>
                <hr class="my-2 rounded"/>
                <label class="white--text d-block mb-8">
                {{ index.section2 && index.section2.channel2 ? index.section2.channel2.fullContent : '' }}
                </label>
                <v-btn rounded color="yellow darken-3" @click="$router.push({ name: 'OrderHome'})">
                    ใช้บริการ Move EXPRESS
                </v-btn>
                </div>
            </v-col>
            <v-col class="col-service" cols="12" md="6">
                <v-img
                    height="280"
                    :src="index.section2 && index.section2.channel2 ? index.section2.channel2.image : ''"
                ></v-img>
            </v-col>
        </v-row>
        <v-row>
            <label class="mx-auto my-5 text-h6 font-weight-bold yellow--text text--darken-3">จุดเด่นของบริการ MOVE EXPRESS</label>
        </v-row>
        <v-row class="mt-3 mx-0 text-center">
            <!-- 1. -->
            <v-col cols="12" md="4">
                <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
                    <div style="height: 100%; padding-top: 25px;"> 
                    <v-img
                        class="mx-auto"
                        src="/images/same_day_icon1.png"
                        width="70"
                        contain
                    ></v-img>
                    </div>
                </div>
                <label class="white--text font-weight-bold d-block">Just In Time</label>
                <label class="grey--text d-block">การขนส่งที่รวดเร็ว ตามเวลาที่กำหนด</label>
            </v-col>
            <!-- 2. -->
            <v-col cols="12" md="4">
                <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
                    <div style="height: 100%; padding-top: 15px;"> 
                    <v-img
                        class="mx-auto"
                        src="/images/same_day_icon2.png"
                        width="60"
                        contain
                    ></v-img>
                    </div>
                </div>
                <label class="white--text font-weight-bold d-block">Precision</label>
                <label class="grey--text d-block">สินค้าส่งถึงมือลูกค้าอย่างถูกต้อง</label>
            </v-col>
            <!-- 3. -->
            <v-col cols="12" md="4">
                <div class="rounded-circle d-block mx-auto mb-2" style="width: 100px; height: 100px; background-color: #CB9B3E;">
                    <div style="height: 100%; padding-top: 15px;"> 
                    <v-img
                        class="mx-auto"
                        src="/images/same_day_icon3.png"
                        width="50"
                        contain
                    ></v-img>
                    </div>
                </div>
                <label class="white--text font-weight-bold d-block">Security And Insurance</label>
                <label class="grey--text d-block">ความปลอดภัยในการขนส่ง และรับประกันสินค้าเต็มจำนวน</label>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    data: () => ({
        index: {
            section1: {},
            section2: {}
        }
    }),
    async mounted(){
        try{
        const response = await axios.get(process.env.VUE_APP_BASE_URL + '/contents/customer-index');
        this.index = response.data;
      }catch(error){
        this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
        this.$refs.dialogError.show();
      }
    }
};
</script>