<template>
    <v-container class="my-10">
        <v-row class="mb-10" align="center" justify="center">
            <label class="font-weight-bold yellow--text text--darken-3">ดูบริการอื่นๆ</label>
        </v-row>
        <v-row>
            <v-col align="center" cols="12" md="6">
                <div class="ml-md-auto" style="max-width: 320px;">
                     <v-img
                        class="d-block mb-3"
                        height="250"
                        :src="show.length > 0 ? show[0].image : ''"
                    ></v-img>
                    <label class="yellow--text text--darken-3 d-block text-left" style="border-bottom: 1px solid #C49C4F; width: 100%">
                        {{ show.length > 0 ? show[0].topic : '' }}
                    </label>
                    <label class="d-block grey--text text-left line-text-3" style="height: 75px;">
                        {{ show.length > 0 ? show[0].content : '' }}
                    </label>
                    <label class="d-block yellow--text text--darken-3 text-right label-button"
                        @click="go(show.length > 0 ? show[0].type : '')">
                        อ่านเพิ่มเติม >
                    </label>
                </div>
            </v-col>
            <v-col align="center" cols="12" md="6">
                <div class="mr-md-auto" style="max-width: 320px;">
                     <v-img
                        class="d-block mb-3"
                        height="250"
                        :src="show.length > 1 ? show[1].image : ''"
                    ></v-img>
                    <label class="yellow--text text--darken-3 d-block text-left" style="border-bottom: 1px solid #C49C4F; width: 100%">
                        {{ show.length > 1 ? show[1].topic : '' }}
                    </label>
                    <label class="d-block grey--text text-left line-text-3" style="height: 75px;">
                        {{ show.length > 1 ? show[1].content : '' }}
                    </label>
                    <label class="d-block yellow--text text--darken-3 text-right label-button" 
                        @click="go(show.length > 1 ? show[1].type : '')">
                        อ่านเพิ่มเติม >
                    </label>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        service: [
            { type: 'move-sameday', topic: 'MOVE SAME DAY', content: 'บริการขนส่งสินค้ามีค่าผ่านทาง บริษัท มูฟไว จำกัด แบบส่งสินค้าภายในวัน มีการรับประกันสินค้าสูญหาย 100% ซึ่งจัดส่งในรูปแบบแมสเซ็นเจอร์ของบริษัท', image: '/images/move-sameday.jpg' },
            { type: 'move-express', topic: 'MOVE EXPRESS', content: 'บริการขนส่งสินค้ามีค่าผ่านทาง บริษัท มูฟไว จำกัด ที่มีการรับประกันสินค้าสูญหาย 100% ซึ่งจัดส่งในรูปแบบด่วนพิเศษ ', image: '/images/move-express.jpg' },
            { type: 'move-transit', topic: 'MOVE TRANSIT', content: 'บริการจัดส่งสินค้ามีค่า ผ่านทาง บริษัท มูฟไว จำกัด ที่มีการรับประกันสินค้าสูญหาย 100% ', image: '/images/move-transit.jpg' }
        ],
        show: []
    }),
    methods: {
        go(routerName){
            this.$router.push({ name: 'ServicesDetails', params: { name: routerName } }).catch(() => {});
        },
        refresh(){
            const name = this.$route.params.name;
            this.show = [];
            if('move-sameday' == name){
                this.show.push(this.service[1]);
                this.show.push(this.service[2]);
            }else if('move-express' == name){
                this.show.push(this.service[0]);
                this.show.push(this.service[2]);
            }else if('move-transit' == name){
                this.show.push(this.service[0]);
                this.show.push(this.service[1]);
            }
        }
    },
    mounted(){
       this.refresh();
    },
    watch: {
        '$route'(){
            this.refresh();
        }
    }
};
</script>

<style scoped>
    .label-button:hover{
        cursor: pointer;
    }

    .line-text-3{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
    }
</style>