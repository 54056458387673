<template>
    <div class="mt-n6 mb-10">
        <service-sameday v-if="'move-sameday' == name"/>
        <service-express v-if="'move-express' == name"/>
        <service-transits v-if="'move-transit' == name"/>
        <other-service/>
    </div>
</template>

<script>
import ServiceSameday from './ServiceSameday.vue';
import ServiceExpress from './ServiceExpress.vue';
import ServiceTransits from './ServiceTransits.vue';
import OtherService from '../../components/OtherService.vue';

export default {
    data: () => ({
        name: ''
    }),
    mounted(){
        this.$vuetify.goTo(0);
        const name = this.$route.params.name;
        this.name = name;
        
    },
    watch: {
        // name() {
        //     if('move-sameday' == this.name){
        //         this.serviceName = 'MOVE SAMEDAY';
        //     }else if('move-express' == this.name){
        //         this.serviceName = 'MOVE EXPRESS';
        //     }else{
        //         this.serviceName = 'MOVE TRANSIT';
        //     }
        // }
        '$route'(){
            const name = this.$route.params.name;
            this.name = name;
            this.$vuetify.goTo(0);
        }
    },
    components: {
        ServiceSameday,
        ServiceExpress,
        ServiceTransits,
        OtherService
    }
};
</script>